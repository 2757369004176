

























import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import UserView from "@/views/UserView.vue";
import User from "@/models/User";
import {AuthorityName} from "@/models/Authority";
import Company from "@/models/Company";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component
export default class CompanyTab extends Vue {
	lang: any = getModule(LangModule).lang
	@Prop() readonly company!: Company
	@Ref() readonly form!: HTMLFormElement

}
