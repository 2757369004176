var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"px-0"},[_c('v-row',{staticClass:"py-1 px-3 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.distributors))]),_c('v-spacer'),_c('v-sheet',{attrs:{"color":"transparent"}},[_c('v-text-field',{attrs:{"hide-details":"auto","dense":"","outlined":"","rounded":"","clearable":"","append-icon":"mdi-magnify","label":_vm.lang.search},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.watchOptions($event)}}})],1)],1)],1),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users.items,"hide-default-footer":"","loading":_vm.loading,"mobile-breakpoint":0,"show-select":false,"loading-text":_vm.lang.loading,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults,"disable-sort":""},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.commercialName",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.active",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.source",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.active ? 'success' : 'error',"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.active ? 'mdi-check' : 'mdi-window-close'))]),_vm._v(" "+_vm._s(item.active ? _vm.lang.active : _vm.lang.inactive)+" ")],1)]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [(item.isCompanyManager)?_c('v-chip',{staticClass:"mx-2",attrs:{"small":"","color":"blue","dark":""}},[_vm._v(" Company Manager ")]):_vm._e()]}}])})],1),(_vm.users.totalItems )?_c('v-card-actions',[_c('v-spacer'),_c('h5',{staticClass:"grey--text text--darken-2 font-weight-medium"},[_vm._v(" Mostrando "+_vm._s(_vm.users.items.length)+" de "+_vm._s(_vm.users.totalItems)+" resultados. ")]),_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }